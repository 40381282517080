<template>
  <div>
    <h4>Isola</h4>
    <hr>
      <CRow>
        <CCol sm="12" lg="6">
          <DashboardIslandOrders/>
        </CCol>
        <CCol sm="12" lg="6">
          <DashboardIslandPlan/>
        </CCol>
      </CRow>
  </div>
</template>

<script>
import DashboardIslandOrders from "./DashboardIslandOrders.vue";
import DashboardIslandPlan from "./DashboardIslandPlan.vue";

export default {
  data() {
    return {};
  },
  components: {
    DashboardIslandOrders,
    DashboardIslandPlan,
  },
};
</script>
